/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class UserService {
  /**
   * Get keycloak user info.
   */
  static userControllerGetUser(options: IRequestOptions = {}): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class HotelsService {
  /**
   * Search hotels by attributes. Pagination and localization included
   */
  static hotelsControllerFindAll(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      attributes?: string;
      /**  */
      countries?: string;
      /**  */
      regions?: string;
      /**  */
      cities?: string;
      /**  */
      cityDistricts?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HotelDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotels/searchByAttributes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        attributes: params['attributes'],
        countries: params['countries'],
        regions: params['regions'],
        cities: params['cities'],
        cityDistricts: params['cityDistricts']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all hotel attributes. Pagination and localization included
   */
  static hotelsControllerFindAttributes(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      attributeGroups: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HotelAttributeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotels/attributes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { language: params['language'], attributeGroups: params['attributeGroups'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all hotel attribute groups. Pagination and localization included
   */
  static hotelsControllerFindAttributeGroups(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HotelAttributeGroupDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotels/attribute-groups';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all promotion labels. Pagination and localization included
   */
  static hotelsControllerFindPromotionLabels(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      promotionLabels?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedPromotionLabelDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotels/promotionLabels';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        promotionLabels: params['promotionLabels']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get single hotel. Localization included
   */
  static hotelsControllerGetHotel(
    params: {
      /**  */
      id: string;
      /** The language in which to return results */
      language: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HotelDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotels/hotel/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { language: params['language'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get single hotel by slug. Localization included
   */
  static hotelsControllerGetHotelBySlug(
    params: {
      /**  */
      slug: string;
      /** The language in which to return results */
      language: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HotelDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotels/hotel/slug/{slug}';
      url = url.replace('{slug}', params['slug'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { language: params['language'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class LocationsService {
  /**
   * Search countries. Pagination included
   */
  static locationControllerFindAllCountries(
    params: {
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationUnitDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/locations/countries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], lastId: params['lastId'], perPage: params['perPage'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Search regions. Pagination included
   */
  static locationControllerFindAllRegions(
    params: {
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationUnitDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/locations/regions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], lastId: params['lastId'], perPage: params['perPage'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Search cities. Pagination included
   */
  static locationControllerFindAllCities(
    params: {
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationUnitDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/locations/cities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], lastId: params['lastId'], perPage: params['perPage'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Search city districts. Pagination included
   */
  static locationControllerFindAllCityDistricts(
    params: {
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationUnitDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/locations/cityDistricts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], lastId: params['lastId'], perPage: params['perPage'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class HotelUnitGroupService {
  /**
   * Get all hotel unit group attributes. Pagination and localization included
   */
  static hotelUnitGroupControllerFindUnitGroupAttributes(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      unitGroupAttributeGroups: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HotelUnitGroupAttributeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotel-unit-groups/attributes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { language: params['language'], unitGroupAttributeGroups: params['unitGroupAttributeGroups'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all hotel attribute groups. Pagination and localization included
   */
  static hotelUnitGroupControllerFindUnitGroupAttributeGroups(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HotelUnitGroupAttributeGroupDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotel-unit-groups/attribute-groups';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all hotel unit group promotion labels. Pagination and localization included
   */
  static hotelUnitGroupControllerFindHotelUnitGroupPromotionLabels(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      promotionLabels?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExtendedPromotionLabelDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotel-unit-groups/unitGroup/promotionLabels';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        promotionLabels: params['promotionLabels']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get single unit group. Localization included
   */
  static hotelUnitGroupControllerGetUnitGroup(
    params: {
      /**  */
      id: string;
      /** The language in which to return results */
      language: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HotelUnitGroupDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotel-unit-groups/unitGroup/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { language: params['language'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all available timeslices
   */
  static hotelUnitGroupControllerGetUnitGroupCalendar(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      unitGroupId: string;
      /**  */
      ratePlanId: string;
      /**  */
      from: string;
      /**  */
      to: string;
      /**  */
      adults: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HotelUnitGroupCalendarDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotel-unit-groups/getUnitGroupCalendar';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        unitGroupId: params['unitGroupId'],
        ratePlanId: params['ratePlanId'],
        from: params['from'],
        to: params['to'],
        adults: params['adults']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Search unit groups by attributes. Pagination and localization included
   */
  static hotelUnitGroupControllerFindUnitGroups(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      attributes?: string;
      /**  */
      hotelAttributes?: string;
      /**  */
      hotelIds?: string;
      /**  */
      unitGroupIds?: string;
      /**  */
      countries?: string;
      /**  */
      regions?: string;
      /**  */
      cities?: string;
      /**  */
      cityDistricts?: string;
      /**  */
      minArea?: number;
      /**  */
      maxArea?: number;
      /**  */
      sortOrder?: string;
      /**  */
      sortByField?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HotelUnitGroupDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotel-unit-groups/searchUnitGroupsByAttributes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        attributes: params['attributes'],
        hotelAttributes: params['hotelAttributes'],
        hotelIds: params['hotelIds'],
        unitGroupIds: params['unitGroupIds'],
        countries: params['countries'],
        regions: params['regions'],
        cities: params['cities'],
        cityDistricts: params['cityDistricts'],
        minArea: params['minArea'],
        maxArea: params['maxArea'],
        sortOrder: params['sortOrder'],
        sortByField: params['sortByField']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hotelUnitGroupControllerGetContractTypes(
    params: {
      /**  */
      unitGroupCodes: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetContractTypesResponseDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotel-unit-groups/getContractTypes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { unitGroupCodes: params['unitGroupCodes'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hotelUnitGroupControllerGetRatePlansAvailability(
    params: {
      /**  */
      unitGroupCodes: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetRatePlansAvailabilityResponseDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotel-unit-groups/getRatePlansAvailability';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { unitGroupCodes: params['unitGroupCodes'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all hotel unit group view types. Pagination included
   */
  static hotelUnitGroupControllerFindAllUnitGroupViewTypes(
    params: {
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HotelUnitGroupViewTypeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/hotel-unit-groups/viewTypes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], lastId: params['lastId'], perPage: params['perPage'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class OffersService {
  /**
   * Search offers for hotels. Pagination and localization included
   */
  static offersControllerSearchOffers(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      attributes?: string;
      /**  */
      hotelAttributes?: string;
      /**  */
      hotelIds?: string;
      /**  */
      unitGroupIds?: string;
      /**  */
      countries?: string;
      /**  */
      regions?: string;
      /**  */
      cities?: string;
      /**  */
      cityDistricts?: string;
      /**  */
      minArea?: number;
      /**  */
      maxArea?: number;
      /**  */
      sortOrder?: string;
      /**  */
      sortByField?: string;
      /**  */
      arrivalDate: string;
      /**  */
      departureDate: string;
      /**  */
      adults: number;
      /**  */
      children?: number;
      /**  */
      minPrice?: number;
      /**  */
      maxPrice?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchOffersResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offers/searchOffers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        attributes: params['attributes'],
        hotelAttributes: params['hotelAttributes'],
        hotelIds: params['hotelIds'],
        unitGroupIds: params['unitGroupIds'],
        countries: params['countries'],
        regions: params['regions'],
        cities: params['cities'],
        cityDistricts: params['cityDistricts'],
        minArea: params['minArea'],
        maxArea: params['maxArea'],
        sortOrder: params['sortOrder'],
        sortByField: params['sortByField'],
        arrivalDate: params['arrivalDate'],
        departureDate: params['departureDate'],
        adults: params['adults'],
        children: params['children'],
        minPrice: params['minPrice'],
        maxPrice: params['maxPrice']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get single offer for hotel. Pagination and localization included
   */
  static offersControllerGetOffer(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      attributes?: string;
      /**  */
      hotelAttributes?: string;
      /**  */
      hotelIds?: string;
      /**  */
      unitGroupIds?: string;
      /**  */
      countries?: string;
      /**  */
      regions?: string;
      /**  */
      cities?: string;
      /**  */
      cityDistricts?: string;
      /**  */
      minArea?: number;
      /**  */
      maxArea?: number;
      /**  */
      sortOrder?: string;
      /**  */
      sortByField?: string;
      /**  */
      arrivalDate: string;
      /**  */
      departureDate: string;
      /**  */
      adults: number;
      /**  */
      children?: number;
      /**  */
      hotelId: string;
      /**  */
      unitGroupId: string;
      /**  */
      promoCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offers/getOffer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        attributes: params['attributes'],
        hotelAttributes: params['hotelAttributes'],
        hotelIds: params['hotelIds'],
        unitGroupIds: params['unitGroupIds'],
        countries: params['countries'],
        regions: params['regions'],
        cities: params['cities'],
        cityDistricts: params['cityDistricts'],
        minArea: params['minArea'],
        maxArea: params['maxArea'],
        sortOrder: params['sortOrder'],
        sortByField: params['sortByField'],
        arrivalDate: params['arrivalDate'],
        departureDate: params['departureDate'],
        adults: params['adults'],
        children: params['children'],
        hotelId: params['hotelId'],
        unitGroupId: params['unitGroupId'],
        promoCode: params['promoCode']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get future offers. Pagination and localization included
   */
  static offersControllerFutureOffers(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offers/futureOffers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class BookingService {
  /**
   * Create booking for offer
   */
  static bookingControllerCreateBooking(
    params: {
      /** The language in which to return results */
      language: string;
      /** requestBody */
      body?: CreateBookingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateBookingResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/booking/createBooking';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { language: params['language'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get booking summary
   */
  static bookingControllerBookingSummary(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      bookingId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BookingSummaryResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/booking/summary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { language: params['language'], bookingId: params['bookingId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get create booking machine state
   */
  static bookingControllerCreateBookingState(
    params: {
      /**  */
      stateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateBookingMachineStateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/booking/create/state';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { stateId: params['stateId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get cancel booking machine state
   */
  static bookingControllerCancelBookingState(
    params: {
      /**  */
      stateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CancelBookingMachineStateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/booking/cancel/state';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { stateId: params['stateId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get create room change machine state
   */
  static bookingControllerCreateRoomChangeState(
    params: {
      /**  */
      stateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoomChangeMachineStateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/booking/createRoomChange/state';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { stateId: params['stateId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Search owner deny reasons. Pagination included
   */
  static bookingControllerFindAllOwnerDenyReasons(
    params: {
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BookingOwnerDenyReasonDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/booking/ownerDenyReasons';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], lastId: params['lastId'], perPage: params['perPage'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bookingControllerCheckBookingCancellationAvailability(
    params: {
      /**  */
      apaleoBookingId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/booking/cancel/availability';

      const configs: IRequestConfig = getConfigs('head', 'application/json', url, options);
      configs.params = { apaleoBookingId: params['apaleoBookingId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Room change
   */
  static bookingControllerRoomChange(
    params: {
      /** requestBody */
      body?: RoomChangeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoomChangeResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/booking/roomChange';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class UrlMappingsService {
  /**
   * Get single url mapping
   */
  static urlMappingsControllerFindOne(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UrlMappingDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/url-mappings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class PaymentService {
  /**
   * Get payment status
   */
  static paymentControllerPaymentStatus(
    params: {
      /**  */
      paymentId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetPaymentStatusResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/payment/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { paymentId: params['paymentId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get payment providers
   */
  static paymentControllerPaymentProviders(options: IRequestOptions = {}): Promise<PaymentProviderDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/payment/paymentProviders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get cloud payments widget
   */
  static paymentControllerCloudPaymentsWidget(
    params: {
      /**  */
      paymentId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CloudPaymentsWidgetDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/payment/cloudpayments/widget';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { paymentId: params['paymentId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class WebhooksPayriffService {
  /**
   *
   */
  static payriffWebhookControllerWebhookRedirect(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/webhooks/payriff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static payriffWebhookControllerWebhook(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/webhooks/payriff';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class WebhooksCibService {
  /**
   *
   */
  static cibWebhookControllerWebhookRedirect(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/webhooks/cib';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class WebhooksYigimService {
  /**
   *
   */
  static yigimWebhookControllerWebhook(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/webhooks/yigim';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class WebhooksCloudpaymentsService {
  /**
   *
   */
  static cloudPaymentsWebhookControllerVerifyWebhook(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/webhooks/cloudpayments/verify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cloudPaymentsWebhookControllerPayWebhook(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/webhooks/cloudpayments/pay';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cloudPaymentsWebhookControllerFailWebhook(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/webhooks/cloudpayments/fail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class OwnerAccountService {
  /**
   * Get a list of bookings
   */
  static ownerAccountControllerBookings(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      bookingStatus?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OwnerAccountBookingResponseDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/bookings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        bookingStatus: params['bookingStatus']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get booking
   */
  static ownerAccountControllerBooking(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      bookingId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OwnerAccountBookingResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/bookings/{bookingId}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { language: params['language'], bookingId: params['bookingId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Confirm waiting booking
   */
  static ownerAccountControllerConfirmBooking(
    params: {
      /** requestBody */
      body?: OwnerAccountConfirmBookingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/confirmBooking';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Deny waiting booking
   */
  static ownerAccountControllerDenyBooking(
    params: {
      /** requestBody */
      body?: OwnerAccountDenyBookingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/denyBooking';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update owner account
   */
  static ownerAccountControllerUpdateOwnerAccount(
    params: {
      /** requestBody */
      body?: OwnerAccountUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/info/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get owner account
   */
  static ownerAccountControllerGetOwnerAccount(options: IRequestOptions = {}): Promise<OwnerAccountResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get unit groups
   */
  static ownerAccountControllerUnitGroups(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      unitGroupIds?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OwnerAccountUnitGroupResponseDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/unitGroups';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        unitGroupIds: params['unitGroupIds']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Restrict specific unit group available dates
   */
  static ownerAccountControllerRestrictUnitGroupAvailability(
    params: {
      /**  */
      unitGroupId: string;
      /** requestBody */
      body?: OwnerAccountRestrictUnitGroupAvailabilityDateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/restrictUnitGroupAvailability/{unitGroupId}';
      url = url.replace('{unitGroupId}', params['unitGroupId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get unit groups calendar events
   */
  static ownerAccountControllerUnitGroupsCalendarEvents(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      fromDate: string;
      /**  */
      toDate: string;
      /**  */
      unitGroupIds?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OwnerAccountUnitGroupCalendarEventResponseDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/unitGroups/calendarEvents';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        unitGroupIds: params['unitGroupIds']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Set price for unit group
   */
  static ownerAccountControllerSetUnitGroupPrice(
    params: {
      /**  */
      unitGroupId: string;
      /** requestBody */
      body?: SetUnitGroupPriceRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/unitGroups/{unitGroupId}/setPrice';
      url = url.replace('{unitGroupId}', params['unitGroupId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Rate booking
   */
  static ownerAccountControllerRateBooking(
    params: {
      /** requestBody */
      body?: RateBookingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/rateBooking';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get booking rate
   */
  static ownerAccountControllerBookingRate(
    params: {
      /**  */
      bookingId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BookingRateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/bookingRates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { bookingId: params['bookingId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get booking deny
   */
  static ownerAccountControllerBookingDeny(
    params: {
      /**  */
      bookingId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BookingDenyDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/bookingDeny';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { bookingId: params['bookingId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Upload unit creation media
   */
  static ownerAccountControllerUploadUnitCreationMedia(
    params: {
      /**  */
      files: [];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OwnerUnitCreationRequestUploadResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/uploadUnitCreationMedia';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete unit creation media
   */
  static ownerAccountControllerDeleteUnitCreationMedia(
    params: {
      /** requestBody */
      body?: OwnerUnitCreationRequestMediaDeleteDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OwnerUnitCreationRequestDeleteResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/deleteUnitCreationMedia';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Upload avatar
   */
  static ownerAccountControllerUploadOwnerAvatar(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OwnerAvatarUploadResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/uploadAvatar';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Upload signed owner agreement
   */
  static ownerAccountControllerUploadOwnerAgreement(
    params: {
      /**  */
      unitCreationRequestId: number;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OwnerAgreementUploadResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/uploadAgreement/{unitCreationRequestId}';
      url = url.replace('{unitCreationRequestId}', params['unitCreationRequestId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Request unit creation
   */
  static ownerAccountControllerRequestUnitCreation(
    params: {
      /** requestBody */
      body?: OwnerUnitCreationRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OwnerUnitCreationRequestCreatedResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/requestUnitCreation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a list of unit creation requests
   */
  static ownerAccountControllerUnitCreationRequests(
    options: IRequestOptions = {}
  ): Promise<OwnerUnitCreationRequestResponseDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/unitCreationRequests';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a unit creation request
   */
  static ownerAccountControllerUnitCreationRequest(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OwnerUnitCreationRequestResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/unitCreationRequests/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Change unitGroup status
   */
  static ownerAccountControllerChangeUnitGroupStatus(
    params: {
      /** requestBody */
      body?: OwnerAccountChangeUnitGroupStatusDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/changeUnitGroupStatus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get owner signed agreements
   */
  static ownerAccountControllerOwnerSignedAgreements(
    options: IRequestOptions = {}
  ): Promise<OwnerSignedAgreementResponseDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/ownerAgreements';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get unit group patches count
   */
  static ownerAccountControllerPatchesCount(
    params: {
      /**  */
      unitGroupId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OwnerAccountUnitGroupPatchesCountResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-account/patches/count';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { unitGroupId: params['unitGroupId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class RestaurantsService {
  /**
   * Create QR for catering
   */
  static restaurantsControllerCreateCateringQr(
    params: {
      /** requestBody */
      body?: CreateCateringQRDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateCateringQRResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/restaurants/createCateringQR';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Verify QR for catering
   */
  static restaurantsControllerVerifyCateringQr(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      qrPayload: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VerifyCateringQRResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/restaurants/verifyCateringQR';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { language: params['language'], qrPayload: params['qrPayload'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Confirm QR for catering
   */
  static restaurantsControllerConfirmCateringQr(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      qrPayload: string;
      /** requestBody */
      body?: ConfirmCateringQRDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConfirmCateringQRResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/restaurants/confirmCateringQR';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { language: params['language'], qrPayload: params['qrPayload'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class GuestsService {
  /**
   * Get guest info
   */
  static guestsControllerGetGuestInfo(
    params: {
      /**  */
      guestId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuestDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/guests/info/{guestId}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { guestId: params['guestId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update guest info
   */
  static guestsControllerUpdateGuestInfo(
    params: {
      /** requestBody */
      body?: GuestInfoUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/guests/info/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a list of bookings
   */
  static guestsControllerBookings(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      page?: number;
      /**  */
      lastId?: string;
      /**  */
      perPage: number;
      /**  */
      bookingStatus?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuestBookingResponseDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/guests/bookings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        page: params['page'],
        lastId: params['lastId'],
        perPage: params['perPage'],
        bookingStatus: params['bookingStatus']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get booking
   */
  static guestsControllerBooking(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      bookingId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuestBookingResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/guests/bookings/{bookingId}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { language: params['language'], bookingId: params['bookingId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Cancel booking
   */
  static guestsControllerCancelBooking(
    params: {
      /** requestBody */
      body?: GuestCancelBookingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/guests/cancelBooking';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class WebhooksKeycloakService {
  /**
   *
   */
  static keycloakWebhookControllerWebhook(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/webhooks/keycloak';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ContentService {
  /**
   *
   */
  static contentWebhookControllerWebhook(
    params: {
      /** requestBody */
      body?: ContentWebhook;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/webhooks/content';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class OwnerPayoutsService {
  /**
   * Get owner payouts
   */
  static ownerPayoutsControllerGetOwnerPayouts(
    params: {
      /**  */
      preReportId: string;
      /**  */
      contractType: string;
      /**  */
      token: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetOwnerPayoutsResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/owner-payouts/getOwnerPayouts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        preReportId: params['preReportId'],
        contractType: params['contractType'],
        token: params['token']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class DocumentBuilderService {
  /**
   * Get document
   */
  static documentBuilderControllerDocument(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      topic: string;
      /**  */
      slug: string;
      /**  */
      lookupId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/document-builder/document';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        topic: params['topic'],
        slug: params['slug'],
        lookupId: params['lookupId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get document keywords
   */
  static documentBuilderControllerDocumentKeywords(
    params: {
      /** The language in which to return results */
      language: string;
      /**  */
      topic: string;
      /**  */
      slug: string;
      /**  */
      lookupId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentKeywordsResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/document-builder/documentKeywords';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        language: params['language'],
        topic: params['topic'],
        slug: params['slug'],
        lookupId: params['lookupId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class PmsService {
  /**
   * Get list of all credentials clients
   */
  static pmsControllerGetCredentialsClients(
    options: IRequestOptions = {}
  ): Promise<GetCredentialsClientsResponseDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/pms/credentials-clients';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get unit group closed dates
   */
  static pmsControllerGetUnitGroupsClosedDates(
    params: {
      /**  */
      apaleoPropertyId: string;
      /**  */
      fromDate: string;
      /**  */
      toDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetUnitGroupsClosedDatesResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/pms/unit-groups/closed-dates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        apaleoPropertyId: params['apaleoPropertyId'],
        fromDate: params['fromDate'],
        toDate: params['toDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export interface UserAttributeDto {
  /**  */
  key: string;

  /**  */
  value: string;
}

export interface UserDto {
  /**  */
  id: string;

  /**  */
  firstname: string;

  /**  */
  lastname: string;

  /**  */
  email: string;

  /**  */
  email_verified: boolean;

  /**  */
  roles: EnumUserDtoRoles[];

  /**  */
  attributes: UserAttributeDto[];
}

export interface MediaDto {
  /**  */
  url: string;

  /**  */
  media_type: EnumMediaDtoMediaType;
}

export interface SEOMetadataDto {
  /**  */
  title: string;

  /**  */
  description?: string;

  /**  */
  keywords: string[];
}

export interface HotelAttributePrimaryDetailsDto {
  /**  */
  id: number;
}

export interface PromotionLabelDto {
  /**  */
  id: string;
}

export interface LocationDto {
  /**  */
  id: string;

  /**  */
  country: string;

  /**  */
  countryId: number;

  /**  */
  region: string;

  /**  */
  regionId: number;

  /**  */
  city: string;

  /**  */
  cityId: number;

  /**  */
  cityDistrict: string;

  /**  */
  cityDistrictId: number;

  /**  */
  coordinates?: object;
}

export interface HotelDto {
  /**  */
  id: string;

  /**  */
  slug: string;

  /**  */
  title: string;

  /**  */
  shortDescription: string;

  /**  */
  description: string;

  /**  */
  media: MediaDto[];

  /**  */
  seoMetadata?: CombinedSeoMetadataTypes;

  /**  */
  pricePerNight: number;

  /**  */
  attributes: HotelAttributePrimaryDetailsDto[];

  /**  */
  promotionLabels: PromotionLabelDto[];

  /**  */
  location: LocationDto;

  /**  */
  contactNumber: string;
}

export interface HotelAttributeDto {
  /**  */
  id: number;

  /**  */
  group_id?: number;

  /**  */
  content_id?: number;
}

export interface HotelAttributeGroupDto {
  /**  */
  id: number;

  /**  */
  content_id?: number;
}

export interface ExtendedPromotionLabelDto {
  /**  */
  id: string;

  /**  */
  title: string;

  /**  */
  backgroundColor: string;
}

export interface LocationUnitDto {
  /**  */
  id: number;
}

export interface HotelUnitGroupAttributeDto {
  /**  */
  id: number;

  /**  */
  group_id?: number;

  /**  */
  content_id?: number;
}

export interface HotelUnitGroupAttributeGroupDto {
  /**  */
  id: number;

  /**  */
  content_id?: number;
}

export interface HotelUnitGroupAttributePrimaryDetailsDto {
  /**  */
  id: number;
}

export interface PriceDetailsDto {
  /**  */
  priceWithoutTax: number;

  /**  */
  priceWithTax: number;

  /**  */
  taxAmount?: number;

  /**  */
  taxRate?: number;

  /**  */
  discountRate?: number;

  /**  */
  priceWithoutTaxBeforeDiscount: number;

  /**  */
  priceWithTaxBeforeDiscount: number;
}

export interface HotelUnitGroupRatePlanDto {
  /**  */
  online: string;

  /**  */
  offline: string;

  /**  */
  walkin: string;
}

export interface HotelUnitGroupDto {
  /**  */
  id: string;

  /**  */
  hotelId: string;

  /**  */
  propertyId: string;

  /**  */
  title: string;

  /**  */
  shortDescription: string;

  /**  */
  description: string;

  /**  */
  media: MediaDto[];

  /**  */
  hotel: HotelDto;

  /**  */
  attributes: HotelUnitGroupAttributePrimaryDetailsDto[];

  /**  */
  promotionLabels: PromotionLabelDto[];

  /**  */
  pricePerNightDetails?: PriceDetailsDto;

  /**  */
  ratePlanId: string;

  /**  */
  maxPersons: number;

  /**  */
  confirmationType: string;

  /**  */
  minStayDays: number;

  /**  */
  area: number;

  /**  */
  ratePlans: HotelUnitGroupRatePlanDto;

  /**  */
  closed_on_arrival?: boolean;

  /**  */
  status: string;

  /**  */
  location: LocationDto;
}

export interface HotelUnitGroupTimeSliceDto {
  /**  */
  date: Date;

  /**  */
  available: boolean;

  /**  */
  pricePerNightDetails: PriceDetailsDto;
}

export interface HotelUnitGroupCalendarDto {
  /**  */
  timeSlices: HotelUnitGroupTimeSliceDto[];
}

export interface GetContractTypesResponseDto {
  /**  */
  unitGroupCode: string;

  /**  */
  contractType: EnumGetContractTypesResponseDtoContractType;
}

export interface GetRatePlansAvailabilityResponseDto {
  /**  */
  unitGroupCode: string;

  /**  */
  walkinRatePlanAvailable: boolean;
}

export interface HotelUnitGroupViewTypeDto {
  /**  */
  id: string;
}

export interface OfferRatePlanCancellationPolicyDto {
  /**  */
  cancellationFee: PriceDetailsDto;

  /**  */
  cancellationDue: string;
}

export interface OfferAdditionalServiceDto {
  /**  */
  id: string;

  /**  */
  title: string;

  /**  */
  description: string;

  /**  */
  price: PriceDetailsDto;

  /**  */
  count: number;

  /**  */
  pricingMode?: EnumOfferAdditionalServiceDtoPricingMode;

  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  serviceType: EnumOfferAdditionalServiceDtoServiceType;

  /**  */
  availabilityMode?: EnumOfferAdditionalServiceDtoAvailabilityMode;

  /**  */
  postNextDay?: boolean;

  /**  */
  serviceCategory: EnumOfferAdditionalServiceDtoServiceCategory;
}

export interface PromoCodeDto {
  /**  */
  discountIsPercentage: boolean;

  /**  */
  discount: number;
}

export interface OfferRatePlanDto {
  /**  */
  ratePlanId: string;

  /**  */
  paymentMethod: EnumOfferRatePlanDtoPaymentMethod;

  /**  */
  stayPriceDetails: PriceDetailsDto;

  /**  */
  perNightStayPriceDetails: PriceDetailsDto[];

  /**  */
  pricePerNightDetails: PriceDetailsDto;

  /**  */
  priceDetails: PriceDetailsDto;

  /**  */
  cancellationPolicy: OfferRatePlanCancellationPolicyDto;

  /**  */
  additionalServices: OfferAdditionalServiceDto[];

  /**  */
  promoCode: CombinedPromoCodeTypes;
}

export interface OfferDto {
  /**  */
  unitGroupId: string;

  /**  */
  hotelId: string;

  /**  */
  hotel: HotelDto;

  /**  */
  unitGroup: HotelUnitGroupDto;

  /**  */
  ratePlans: OfferRatePlanDto[];

  /**  */
  arrivalDate: string;

  /**  */
  departureDate: string;

  /**  */
  promoCode: CombinedPromoCodeTypes;
}

export interface OfferMetadataDto {
  /**  */
  minPrice?: number;

  /**  */
  maxPrice?: number;
}

export interface SearchOffersResponseDto {
  /**  */
  offers: OfferDto[];

  /**  */
  metadata: OfferMetadataDto;
}

export interface ApaleoWebhook {}

export interface CreateBookingGuestDto {
  /**  */
  firstName?: string;

  /**  */
  lastName: string;

  /**  */
  email: string;

  /**  */
  passport?: string;

  /**  */
  gender?: EnumCreateBookingGuestDtoGender;

  /**  */
  dateOfBirth?: string;

  /**  */
  phone?: string;
}

export interface CreateBookingOptionalServiceDto {
  /**  */
  count: number;

  /**  */
  additionalServiceId: string;
}

export interface CreateBookingDto {
  /**  */
  arrivalDate: string;

  /**  */
  departureDate: string;

  /**  */
  adults: number;

  /**  */
  children?: number;

  /**  */
  booker: CreateBookingGuestDto;

  /**  */
  hotelId: string;

  /**  */
  unitGroupId: string;

  /**  */
  optionalServices?: CreateBookingOptionalServiceDto[];

  /**  */
  ratePlanId: string;

  /**  */
  additionalRequest: string;

  /**  */
  paymentProvider: EnumCreateBookingDtoPaymentProvider;

  /**  */
  promoCode?: string;

  /**  */
  referral?: string;

  /**  */
  ymid?: string;
}

export interface CreateBookingResponseDto {
  /**  */
  stateId: string;
}

export interface BookingSummaryGuestResponseDto {
  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  email: string;

  /**  */
  passport: string;

  /**  */
  gender: string;

  /**  */
  dateOfBirth: string;

  /**  */
  phone: string;
}

export interface AdditionalServiceDto {
  /**  */
  id: string;

  /**  */
  title: string;

  /**  */
  description: string;

  /**  */
  booking_id: string;

  /**  */
  serviceDate: Date;

  /**  */
  count: number;

  /**  */
  priceDetails: PriceDetailsDto;

  /**  */
  pricingMode?: EnumAdditionalServiceDtoPricingMode;

  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  serviceType: EnumAdditionalServiceDtoServiceType;

  /**  */
  serviceCategory: EnumAdditionalServiceDtoServiceCategory;
}

export interface BookingSummaryResponseDto {
  /**  */
  id: string;

  /**  */
  arrivalDate: string;

  /**  */
  departureDate: string;

  /**  */
  hotelId: string;

  /**  */
  unitGroupId: string;

  /**  */
  assignedUnit: string;

  /**  */
  adults: number;

  /**  */
  children: number;

  /**  */
  booker: BookingSummaryGuestResponseDto;

  /**  */
  priceDetails: PriceDetailsDto;

  /**  */
  paymentMethod: string;

  /**  */
  additionalServices: AdditionalServiceDto[];

  /**  */
  additionalRequest: string;

  /**  */
  bookingNumber: string;

  /**  */
  bookingStatus: EnumBookingSummaryResponseDtoBookingStatus;

  /**  */
  createBookingMachineStateId: string;

  /**  */
  cancelBookingMachineStateId?: string;

  /**  */
  promoCodeDiscountAmount: number;
}

export interface CreateBookingMachineStateOutputDto {
  /**  */
  _state: EnumCreateBookingMachineStateOutputDtoState;

  /**  */
  failedPaymentAttempts: number;

  /**  */
  paymentUrl?: string;

  /**  */
  bookingId?: string;

  /**  */
  lastPaymentId?: string;

  /**  */
  lastPaymentProvider?: EnumCreateBookingMachineStateOutputDtoLastPaymentProvider;
}

export interface CreateBookingMachineStateDto {
  /**  */
  id: string;

  /**  */
  output: CreateBookingMachineStateOutputDto;
}

export interface CancelBookingMachineStateOutputDto {
  /**  */
  _state: EnumCancelBookingMachineStateOutputDtoState;

  /**  */
  refundedAmount?: number;
}

export interface CancelBookingMachineStateDto {
  /**  */
  id: string;

  /**  */
  output: CancelBookingMachineStateOutputDto;
}

export interface RoomChangeMachineStateOutputDto {
  /**  */
  _state: EnumRoomChangeMachineStateOutputDtoState;

  /**  */
  newReservationId?: string;
}

export interface RoomChangeMachineStateDto {
  /**  */
  id: string;

  /**  */
  output: RoomChangeMachineStateOutputDto;
}

export interface BookingOwnerDenyReasonDto {
  /**  */
  id: number;
}

export interface MonetaryAmountDto {
  /**  */
  amount: number;

  /**  */
  currency: string;
}

export interface TimeSliceDto {
  /**  */
  ratePlanId: string;

  /**  */
  totalGrossAmount: MonetaryAmountDto;
}

export interface RoomChangeDto {
  /**  */
  newReservationId: string;

  /**  */
  channelCode: EnumRoomChangeDtoChannelCode;

  /**  */
  timeSlices: TimeSliceDto[];

  /**  */
  arrivalDate: string;

  /**  */
  departureDate: string;

  /**  */
  adults: number;

  /**  */
  children: number;
}

export interface RoomChangeResponseDto {
  /**  */
  stateId: string;
}

export interface UrlMappingDto {
  /**  */
  short_url: string;

  /**  */
  long_url: string;
}

export interface GetPaymentStatusResponseDto {
  /**  */
  status: string;
}

export interface PaymentProviderDto {
  /**  */
  name: string;

  /**  */
  weight: number;

  /**  */
  isHealthy: boolean;
}

export interface CloudPaymentsWidgetPayerDto {
  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  phone: string;
}

export interface CloudPaymentsWidgetOptionsDto {
  /**  */
  publicId: string;

  /**  */
  description: string;

  /**  */
  amount: number;

  /**  */
  currency: string;

  /**  */
  invoiceId: string;

  /**  */
  email: string;

  /**  */
  requireEmail: boolean;

  /**  */
  language: string;

  /**  */
  applePaySupport: boolean;

  /**  */
  googlePaySupport: boolean;

  /**  */
  yandexPaySupport: boolean;

  /**  */
  tinkoffPaySupport: boolean;

  /**  */
  sbpSupport: boolean;

  /**  */
  data: object;

  /**  */
  retryPayment: boolean;

  /**  */
  payer: CloudPaymentsWidgetPayerDto;
}

export interface CloudPaymentsWidgetDto {
  /**  */
  options: CloudPaymentsWidgetOptionsDto;

  /**  */
  payment_type: EnumCloudPaymentsWidgetDtoPaymentType;
}

export interface OwnerAccountBookingResponseDto {
  /**  */
  bookingSummary: BookingSummaryResponseDto;

  /**  */
  unitGroup: HotelUnitGroupDto;
}

export interface OwnerAccountConfirmBookingDto {
  /**  */
  bookingId: string;
}

export interface OwnerAccountDenyBookingDto {
  /**  */
  bookingId: string;

  /**  */
  ownerDenyReasonId: number;
}

export interface UpdateContactDto {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  phone?: string;
}

export interface OwnerAccountUpdateDto {
  /**  */
  ownerId: string;

  /**  */
  contact?: UpdateContactDto;

  /**  */
  contactLanguage?: EnumOwnerAccountUpdateDtoContactLanguage;
}

export interface ContactDto {
  /**  */
  id: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  email: string;

  /**  */
  phone: string;
}

export interface OwnerAccountResponseDto {
  /**  */
  ownerId: string;

  /**  */
  contact: ContactDto;

  /**  */
  contactLanguage: EnumOwnerAccountResponseDtoContactLanguage;

  /**  */
  avatar?: MediaDto;
}

export interface OwnerAccountUnitGroupResponseDto {
  /**  */
  unitGroup: HotelUnitGroupDto;
}

export interface OwnerAccountRestrictUnitGroupAvailabilityDateDto {
  /**  */
  fromDate: string;

  /**  */
  toDate: string;

  /**  */
  isClosed: boolean;
}

export interface OwnerAccountUnitGroupCalendarEventResponseDto {
  /**  */
  start: string;

  /**  */
  end: string;

  /**  */
  eventType: EnumOwnerAccountUnitGroupCalendarEventResponseDtoEventType;

  /**  */
  unitGroupId: string;

  /**  */
  booking?: BookingSummaryResponseDto;
}

export interface SetUnitGroupPriceRequestDto {
  /**  */
  fromDate: string;

  /**  */
  toDate: string;

  /**  */
  newPrice: number;

  /**  */
  priceIncrease: number;

  /**  */
  newPriceWeekend: number;

  /**  */
  priceIncreaseWeekend: number;

  /**  */
  priceIncreaseByPercentage: number;

  /**  */
  priceIncreaseWeekendByPercentage: number;

  /**  */
  minLengthNights: number;

  /**  */
  maxLengthNights: number;
}

export interface RateBookingDto {
  /**  */
  bookingId: string;

  /**  */
  rate: number;

  /**  */
  comment?: string;
}

export interface BookingRateDto {
  /**  */
  bookingId: string;

  /**  */
  rate: number;

  /**  */
  comment: string;
}

export interface BookingDenyDto {
  /**  */
  bookingId: string;

  /**  */
  ownerDenyReason: BookingOwnerDenyReasonDto;

  /**  */
  deniedOwnerId: string;
}

export interface OwnerUnitCreationRequestUploadResponseDto {
  /**  */
  ids: number[];
}

export interface OwnerUnitCreationRequestMediaDeleteDto {
  /**  */
  ids: number[];
}

export interface OwnerUnitCreationRequestDeleteResponseDto {
  /**  */
  ids: number[];
}

export interface OwnerAvatarUploadResponseDto {
  /**  */
  url: string;

  /**  */
  media_type: EnumOwnerAvatarUploadResponseDtoMediaType;
}

export interface OwnerAgreementUploadResponseDto {
  /**  */
  url: string;

  /**  */
  type: string;

  /**  */
  title: string;

  /**  */
  document_type: EnumOwnerAgreementUploadResponseDtoDocumentType;

  /**  */
  fullName: string;
}

export interface OwnerUnitCreationRequestDto {
  /**  */
  title?: string;

  /**  */
  unit_group_type?: string;

  /**  */
  city_id?: number;

  /**  */
  postcode?: string;

  /**  */
  city_district?: string;

  /**  */
  building_number?: string;

  /**  */
  entrance?: string;

  /**  */
  apartment_number?: string;

  /**  */
  residential_complex_name?: string;

  /**  */
  subway_station?: string;

  /**  */
  area?: number;

  /**  */
  rooms_count?: number;

  /**  */
  bedrooms_count?: number;

  /**  */
  beds_count?: number;

  /**  */
  bathrooms_count?: number;

  /**  */
  guests_count?: number;

  /**  */
  allows_animals?: boolean;

  /**  */
  allows_smoking?: boolean;

  /**  */
  allows_children?: boolean;

  /**  */
  view_type_id?: number;

  /**  */
  parking?: EnumOwnerUnitCreationRequestDtoParking;

  /**  */
  building_type?: EnumOwnerUnitCreationRequestDtoBuildingType;

  /**  */
  floor_count?: number;

  /**  */
  floor?: string;

  /**  */
  has_elevator?: boolean;

  /**  */
  has_balcony?: boolean;

  /**  */
  description?: string;

  /**  */
  media?: number[];

  /**  */
  owner_type?: EnumOwnerUnitCreationRequestDtoOwnerType;

  /**  */
  owner_status?: EnumOwnerUnitCreationRequestDtoOwnerStatus;

  /**  */
  beneficiary_name?: string;

  /**  */
  iban?: string;

  /**  */
  account_currency?: string;

  /**  */
  tin?: string;

  /**  */
  beneficiary_bank?: string;

  /**  */
  swift?: string;

  /**  */
  correspondent_account?: string;

  /**  */
  bank_code?: string;

  /**  */
  bank_tin?: string;

  /**  */
  bank_address?: string;

  /**  */
  bank_phone?: string;

  /**  */
  bank_fax?: string;

  /**  */
  bank_email?: string;

  /**  */
  owner_name?: string;

  /**  */
  owner_surname?: string;

  /**  */
  owner_phone?: string;

  /**  */
  owner_patronymic?: string;

  /**  */
  owner_id_number?: string;

  /**  */
  owner_fin?: string;

  /**  */
  owner_passport_scan?: number;

  /**  */
  rep_name?: string;

  /**  */
  rep_surname?: string;

  /**  */
  rep_patronymic?: string;

  /**  */
  rep_id_number?: string;

  /**  */
  rep_fin?: string;

  /**  */
  rep_passport_scan?: number;

  /**  */
  owner_poa_number?: string;

  /**  */
  owner_poa_signature_date?: Date;

  /**  */
  owner_poa_scan?: number;

  /**  */
  legal_entity_name?: string;

  /**  */
  legal_tin?: string;

  /**  */
  legal_physical_adress?: string;

  /**  */
  legal_address?: string;

  /**  */
  legal_passport_scan?: number;

  /**  */
  legal_tin_scan?: number;

  /**  */
  legal_name?: string;

  /**  */
  legal_surname?: string;

  /**  */
  legal_patronymic?: string;

  /**  */
  legal_fin?: string;

  /**  */
  attributes?: number[];
}

export interface OwnerUnitCreationRequestCreatedResponseDto {
  /**  */
  id: number;
}

export interface OwnerUnitCreationRequestResponseDto {
  /**  */
  id: number;

  /**  */
  owner_id: string;

  /**  */
  request_status?: EnumOwnerUnitCreationRequestResponseDtoRequestStatus;

  /**  */
  title?: string;

  /**  */
  unit_group_type?: string;

  /**  */
  city_id?: number;

  /**  */
  postcode?: string;

  /**  */
  city_district?: string;

  /**  */
  building_number?: string;

  /**  */
  entrance?: string;

  /**  */
  apartment_number?: string;

  /**  */
  residential_complex_name?: string;

  /**  */
  subway_station?: string;

  /**  */
  area?: number;

  /**  */
  rooms_count?: number;

  /**  */
  bedrooms_count?: number;

  /**  */
  beds_count?: number;

  /**  */
  bathrooms_count?: number;

  /**  */
  guests_count?: number;

  /**  */
  allows_animals?: boolean;

  /**  */
  allows_smoking?: boolean;

  /**  */
  allows_children?: boolean;

  /**  */
  view_type_id?: number;

  /**  */
  parking?: EnumOwnerUnitCreationRequestResponseDtoParking;

  /**  */
  building_type?: EnumOwnerUnitCreationRequestResponseDtoBuildingType;

  /**  */
  floor_count?: number;

  /**  */
  floor?: string;

  /**  */
  has_elevator?: boolean;

  /**  */
  has_balcony?: boolean;

  /**  */
  description?: string;

  /**  */
  media: string[];

  /**  */
  owner_type?: EnumOwnerUnitCreationRequestResponseDtoOwnerType;

  /**  */
  owner_status?: EnumOwnerUnitCreationRequestResponseDtoOwnerStatus;

  /**  */
  beneficiary_name?: string;

  /**  */
  iban?: string;

  /**  */
  account_currency?: string;

  /**  */
  tin?: string;

  /**  */
  beneficiary_bank?: string;

  /**  */
  swift?: string;

  /**  */
  correspondent_account?: string;

  /**  */
  bank_code?: string;

  /**  */
  bank_tin?: string;

  /**  */
  bank_address?: string;

  /**  */
  bank_phone?: string;

  /**  */
  bank_fax?: string;

  /**  */
  bank_email?: string;

  /**  */
  owner_name?: string;

  /**  */
  owner_surname?: string;

  /**  */
  owner_phone?: string;

  /**  */
  owner_patronymic?: string;

  /**  */
  owner_id_number?: string;

  /**  */
  owner_fin?: string;

  /**  */
  owner_passport_scan?: string;

  /**  */
  rep_name?: string;

  /**  */
  rep_surname?: string;

  /**  */
  rep_patronymic?: string;

  /**  */
  rep_id_number?: string;

  /**  */
  rep_fin?: string;

  /**  */
  rep_passport_scan?: string;

  /**  */
  owner_poa_number?: string;

  /**  */
  owner_poa_signature_date?: Date;

  /**  */
  owner_poa_scan?: string;

  /**  */
  legal_entity_name?: string;

  /**  */
  legal_tin?: string;

  /**  */
  legal_physical_adress?: string;

  /**  */
  legal_address?: string;

  /**  */
  legal_passport_scan?: string;

  /**  */
  legal_tin_scan?: string;

  /**  */
  legal_name?: string;

  /**  */
  legal_surname?: string;

  /**  */
  legal_patronymic?: string;

  /**  */
  legal_fin?: string;

  /**  */
  attributes: string[];
}

export interface OwnerAccountChangeUnitGroupStatusDto {
  /**  */
  unitGroupId: string;

  /**  */
  status: EnumOwnerAccountChangeUnitGroupStatusDtoStatus;
}

export interface DocumentDto {
  /**  */
  url: string;

  /**  */
  type: string;

  /**  */
  title: string;

  /**  */
  document_type: EnumDocumentDtoDocumentType;

  /**  */
  fullName: string;
}

export interface OwnerSignedAgreementResponseDto {
  /**  */
  unitCreationRequestId: number;

  /**  */
  document: DocumentDto;
}

export interface OwnerAccountUnitGroupPatchesCountResponseDto {
  /**  */
  count: number;
}

export interface CreateCateringQRDto {
  /**  */
  bookingId: string;

  /**  */
  hotelAdditionalServiceId: string;
}

export interface CreateCateringQRResponseDto {
  /**  */
  shortUrl: string;
}

export interface RestaurantVisitAdditionalServiceDto {
  /**  */
  id: string;

  /**  */
  title: string;
}

export interface RestaurantVisitDto {
  /**  */
  id: string;

  /**  */
  maxUsagePerDay: number;

  /**  */
  usedCount: number;

  /**  */
  dateOfVisit: string;

  /**  */
  bookingId: string;

  /**  */
  additionalService: RestaurantVisitAdditionalServiceDto;
}

export interface RestaurantBookingSummaryGuestResponseDto {
  /**  */
  firstName: string;

  /**  */
  lastName: string;
}

export interface RestaurantBookingSummaryResponseDto {
  /**  */
  id: string;

  /**  */
  arrivalDate: string;

  /**  */
  departureDate: string;

  /**  */
  hotelId: string;

  /**  */
  unitGroupId: string;

  /**  */
  assignedUnit: string;

  /**  */
  adults: number;

  /**  */
  children: number;

  /**  */
  booker: RestaurantBookingSummaryGuestResponseDto;

  /**  */
  bookingNumber: string;
}

export interface VerifyCateringQRResponseDto {
  /**  */
  restaurantVisit: RestaurantVisitDto;

  /**  */
  booking: RestaurantBookingSummaryResponseDto;
}

export interface CateringConfirmationDto {
  /**  */
  useCount: number;
}

export interface ConfirmCateringQRDto {
  /**  */
  restaurantVisit: CateringConfirmationDto;
}

export interface ConfirmCateringQRResponseDto {
  /**  */
  restaurantVisit: RestaurantVisitDto;

  /**  */
  booking: RestaurantBookingSummaryResponseDto;
}

export interface GuestDto {
  /**  */
  id: string;

  /**  */
  contact: ContactDto;

  /**  */
  nationality: LocationUnitDto;

  /**  */
  country: LocationUnitDto;

  /**  */
  birthDate: Date;

  /**  */
  sex: EnumGuestDtoSex;
}

export interface GuestInfoUpdateDto {
  /**  */
  guestId: string;

  /**  */
  contact?: UpdateContactDto;

  /**  */
  birthDate?: string;

  /**  */
  nationalityId?: number;

  /**  */
  countryId?: number;

  /**  */
  sex?: EnumGuestInfoUpdateDtoSex;
}

export interface GuestBookingResponseDto {
  /**  */
  bookingSummary: BookingSummaryResponseDto;
}

export interface GuestCancelBookingDto {
  /**  */
  bookingId: string;
}

export interface CancelResponseErrorDto {
  /**  */
  code: string;
}

export interface CancelErrorResponseDto {
  /**  */
  error: CancelResponseErrorDto;
}

export interface ContentWebhook {}

export interface GetOwnerPayoutsReportDto {
  /**  */
  createdDate: string;
}

export interface GetOwnerPayoutsOwnerDto {
  /**  */
  id: string;

  /**  */
  first_name: string;

  /**  */
  last_name: string;

  /**  */
  contact_language: string;
}

export interface GetOwnerPayoutsUnitGroupDto {
  /**  */
  ititle: string;
}

export interface GetOwnerPayoutsBookingDto {
  /**  */
  arrivalDate: string;

  /**  */
  departureDate: string;

  /**  */
  folioClosingDate: Date;
}

export interface OwnerPayoutKeysDto {
  /**  */
  GROSS_BOOKING_AMOUNT: number;

  /**  */
  ADDITIONAL_SERVICES_AMOUNT: number;

  /**  */
  ROOM_PRICE_TOTAL: number;

  /**  */
  TOTAL_FEE: number;

  /**  */
  TAX_FEE: number;

  /**  */
  SEAYA_FEE: number;

  /**  */
  T_SERVICE_PAYOUT: number;

  /**  */
  T_SERVICE_FEE: number;

  /**  */
  OWNER_PAYOUT_AMOUNT: number;
}

export interface GetOwnerPayoutsOwnerPayoutDto {
  /**  */
  id: string;

  /**  */
  unitGroup: GetOwnerPayoutsUnitGroupDto;

  /**  */
  booking: GetOwnerPayoutsBookingDto;

  /**  */
  payout: OwnerPayoutKeysDto;
}

export interface GetOwnerPayoutsResponseDto {
  /**  */
  report: GetOwnerPayoutsReportDto;

  /**  */
  owner: GetOwnerPayoutsOwnerDto;

  /**  */
  ownerPayouts: GetOwnerPayoutsOwnerPayoutDto[];

  /**  */
  totalPayouts: OwnerPayoutKeysDto;
}

export interface DocumentResponseDto {
  /**  */
  fileURL: string;
}

export interface DocumentKeywordsResponseDto {
  /**  */
  keywords: object;
}

export interface GetCredentialsClientsResponseDto {
  /**  */
  clientId: string;

  /**  */
  clientName: string;
}

export interface GetUnitGroupsClosedDatesResponseUnitGroupClosedDateDto {
  /**  */
  startDate: string;

  /**  */
  endDate: string;
}

export interface GetUnitGroupsClosedDatesResponseUnitGroupDto {
  /**  */
  apaleoUnitGroupId: string;

  /**  */
  apaleoUnitIds: [];

  /**  */
  closedDates: GetUnitGroupsClosedDatesResponseUnitGroupClosedDateDto[];
}

export interface GetUnitGroupsClosedDatesResponseDto {
  /**  */
  unitGroups: GetUnitGroupsClosedDatesResponseUnitGroupDto[];
}
export enum EnumUserDtoRoles {
  'restaurant.manager' = 'restaurant.manager',
  'restaurant.member' = 'restaurant.member'
}
export enum EnumMediaDtoMediaType {
  'photo' = 'photo',
  'video' = 'video'
}
export type CombinedSeoMetadataTypes = SEOMetadataDto;
export enum EnumGetContractTypesResponseDtoContractType {
  'listing' = 'listing',
  'fixed' = 'fixed',
  'trust' = 'trust'
}
export enum EnumOfferAdditionalServiceDtoPricingMode {
  'room' = 'room',
  'person' = 'person'
}
export enum EnumOfferAdditionalServiceDtoServiceType {
  'mandatory' = 'mandatory',
  'included' = 'included',
  'optional' = 'optional'
}
export enum EnumOfferAdditionalServiceDtoAvailabilityMode {
  'Arrival' = 'Arrival',
  'Departure' = 'Departure',
  'Daily' = 'Daily'
}
export enum EnumOfferAdditionalServiceDtoServiceCategory {
  'accommodation' = 'accommodation',
  'catering' = 'catering',
  'transport' = 'transport'
}
export enum EnumOfferRatePlanDtoPaymentMethod {
  'offline' = 'offline',
  'card' = 'card'
}
export type CombinedPromoCodeTypes = PromoCodeDto;
export enum EnumCreateBookingGuestDtoGender {
  'Female' = 'Female',
  'Male' = 'Male',
  'Other' = 'Other'
}
export enum EnumCreateBookingDtoPaymentProvider {
  'payriff' = 'payriff',
  'cib' = 'cib',
  'offline' = 'offline',
  'yigim' = 'yigim',
  'cloudPayments' = 'cloudPayments'
}
export enum EnumAdditionalServiceDtoPricingMode {
  'room' = 'room',
  'person' = 'person'
}
export enum EnumAdditionalServiceDtoServiceType {
  'mandatory' = 'mandatory',
  'included' = 'included',
  'optional' = 'optional'
}
export enum EnumAdditionalServiceDtoServiceCategory {
  'accommodation' = 'accommodation',
  'catering' = 'catering',
  'transport' = 'transport'
}
export enum EnumBookingSummaryResponseDtoBookingStatus {
  'created' = 'created',
  'cancelled' = 'cancelled',
  'waiting_confirmation' = 'waiting_confirmation',
  'confirmed' = 'confirmed'
}
export enum EnumCreateBookingMachineStateOutputDtoState {
  'init' = 'init',
  'done' = 'done',
  'error' = 'error',
  'bookingCreate' = 'bookingCreate',
  'bookingCreateExisting' = 'bookingCreateExisting',
  'promoCodeUse' = 'promoCodeUse',
  'paymentCreate' = 'paymentCreate',
  'waitForPayment' = 'waitForPayment',
  'checkPayment' = 'checkPayment',
  'failedPayment' = 'failedPayment',
  'assignUnit' = 'assignUnit',
  'cancelledFailedPayment' = 'cancelledFailedPayment',
  'waitingConfirmation' = 'waitingConfirmation',
  'checkConfirmation' = 'checkConfirmation',
  'confirmBooking' = 'confirmBooking',
  'cancelled' = 'cancelled',
  'bookingCompleted' = 'bookingCompleted'
}
export enum EnumCreateBookingMachineStateOutputDtoLastPaymentProvider {
  'payriff' = 'payriff',
  'cib' = 'cib',
  'offline' = 'offline',
  'yigim' = 'yigim',
  'cloudPayments' = 'cloudPayments'
}
export enum EnumCancelBookingMachineStateOutputDtoState {
  'init' = 'init',
  'done' = 'done',
  'error' = 'error',
  'fetchBookingInfo' = 'fetchBookingInfo',
  'cancelApaleoBooking' = 'cancelApaleoBooking',
  'bookingCancel' = 'bookingCancel',
  'refundPayment' = 'refundPayment',
  'closeOutstandingPayment' = 'closeOutstandingPayment',
  'promoCodeReturn' = 'promoCodeReturn'
}
export enum EnumRoomChangeMachineStateOutputDtoState {
  'init' = 'init',
  'done' = 'done',
  'error' = 'error',
  'createBooking' = 'createBooking',
  'waitNewBookingIsCreated' = 'waitNewBookingIsCreated',
  'transferPayments' = 'transferPayments',
  'cancelBooking' = 'cancelBooking',
  'associateRebookedBookings' = 'associateRebookedBookings'
}
export enum EnumRoomChangeDtoChannelCode {
  'Direct' = 'Direct',
  'BookingCom' = 'BookingCom',
  'Ibe' = 'Ibe',
  'ChannelManager' = 'ChannelManager',
  'Expedia' = 'Expedia',
  'Homelike' = 'Homelike',
  'Hrs' = 'Hrs'
}
export enum EnumCloudPaymentsWidgetDtoPaymentType {
  'auth' = 'auth',
  'charge' = 'charge'
}
export enum EnumOwnerAccountUpdateDtoContactLanguage {
  'en' = 'en',
  'ru' = 'ru',
  'az' = 'az'
}
export enum EnumOwnerAccountResponseDtoContactLanguage {
  'en' = 'en',
  'ru' = 'ru',
  'az' = 'az'
}
export enum EnumOwnerAccountUnitGroupCalendarEventResponseDtoEventType {
  'closed' = 'closed',
  'bookingConfirmed' = 'bookingConfirmed',
  'bookingWaitingConfirmation' = 'bookingWaitingConfirmation'
}
export enum EnumOwnerAvatarUploadResponseDtoMediaType {
  'photo' = 'photo',
  'video' = 'video'
}
export enum EnumOwnerAgreementUploadResponseDtoDocumentType {
  'photo' = 'photo',
  'video' = 'video',
  'application' = 'application'
}
export enum EnumOwnerUnitCreationRequestDtoParking {
  'underground' = 'underground',
  'surface' = 'surface',
  'paid' = 'paid',
  'free' = 'free',
  'on_hotel_premises' = 'on_hotel_premises'
}
export enum EnumOwnerUnitCreationRequestDtoBuildingType {
  'old_building' = 'old_building',
  'new_building' = 'new_building'
}
export enum EnumOwnerUnitCreationRequestDtoOwnerType {
  'individual' = 'individual',
  'legal' = 'legal'
}
export enum EnumOwnerUnitCreationRequestDtoOwnerStatus {
  'owner' = 'owner',
  'representative_general_poa' = 'representative_general_poa',
  'representative_other_poa' = 'representative_other_poa',
  'representative_other_poa_additional' = 'representative_other_poa_additional',
  'legal_owner' = 'legal_owner',
  'legal_agent' = 'legal_agent',
  'legal_agency' = 'legal_agency'
}
export enum EnumOwnerUnitCreationRequestResponseDtoRequestStatus {
  'new' = 'new',
  'approved' = 'approved',
  'signing_contract' = 'signing_contract',
  'review' = 'review',
  'rejected' = 'rejected',
  'done' = 'done'
}
export enum EnumOwnerUnitCreationRequestResponseDtoParking {
  'underground' = 'underground',
  'surface' = 'surface',
  'paid' = 'paid',
  'free' = 'free',
  'on_hotel_premises' = 'on_hotel_premises'
}
export enum EnumOwnerUnitCreationRequestResponseDtoBuildingType {
  'old_building' = 'old_building',
  'new_building' = 'new_building'
}
export enum EnumOwnerUnitCreationRequestResponseDtoOwnerType {
  'individual' = 'individual',
  'legal' = 'legal'
}
export enum EnumOwnerUnitCreationRequestResponseDtoOwnerStatus {
  'owner' = 'owner',
  'representative_general_poa' = 'representative_general_poa',
  'representative_other_poa' = 'representative_other_poa',
  'representative_other_poa_additional' = 'representative_other_poa_additional',
  'legal_owner' = 'legal_owner',
  'legal_agent' = 'legal_agent',
  'legal_agency' = 'legal_agency'
}
export enum EnumOwnerAccountChangeUnitGroupStatusDtoStatus {
  'published' = 'published',
  'unpublished' = 'unpublished',
  'unused' = 'unused'
}
export enum EnumDocumentDtoDocumentType {
  'photo' = 'photo',
  'video' = 'video',
  'application' = 'application'
}
export enum EnumGuestDtoSex {
  'male' = 'male',
  'female' = 'female'
}
export enum EnumGuestInfoUpdateDtoSex {
  'male' = 'male',
  'female' = 'female'
}
