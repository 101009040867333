import { SvgIcon, SvgIconProps } from "@mui/material";

// ----------------------------------------------------------------------

// CloseIcon
export function CloseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d={
          "M12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 C17.5228475,22 22,17.5228475 22,12 C22,9.3478351 20.9464316,6.80429597 19.0710678,4.92893219 C17.195704,3.0535684 14.6521649,2 12,2 Z M14.71,13.29 C14.8993127,13.4777666 15.0057983,13.7333625 15.0057983,14 C15.0057983,14.2666375 14.8993127,14.5222334 14.71,14.71 C14.5222334,14.8993127 14.2666375,15.0057983 14,15.0057983 C13.7333625,15.0057983 13.4777666,14.8993127 13.29,14.71 L12,13.41 L10.71,14.71 C10.5222334,14.8993127 10.2666375,15.0057983 10,15.0057983 C9.73336246,15.0057983 9.4777666,14.8993127 9.29,14.71 C9.10068735,14.5222334 8.99420168,14.2666375 8.99420168,14 C8.99420168,13.7333625 9.10068735,13.4777666 9.29,13.29 L10.59,12 L9.29,10.71 C8.89787783,10.3178778 8.89787783,9.68212217 9.29,9.29 C9.68212217,8.89787783 10.3178778,8.89787783 10.71,9.29 L12,10.59 L13.29,9.29 C13.6821222,8.89787783 14.3178778,8.89787783 14.71,9.29 C15.1021222,9.68212217 15.1021222,10.3178778 14.71,10.71 L13.41,12 L14.71,13.29 Z"
        }
      />
    </SvgIcon>
  );
}

// StarIcon
export function StarIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d={
          "M17.56,21 C17.4000767,21.0006435 17.2423316,20.9629218 17.1,20.89 L12,18.22 L6.9,20.89 C6.56213339,21.067663 6.15259539,21.0374771 5.8444287,20.8121966 C5.53626201,20.5869161 5.38323252,20.2058459 5.45,19.83 L6.45,14.2 L2.33,10.2 C2.06805623,9.93860108 1.9718844,9.55391377 2.08,9.2 C2.19824414,8.83742187 2.51242293,8.57366684 2.89,8.52 L8.59,7.69 L11.1,2.56 C11.2670864,2.21500967 11.6166774,1.99588989 12,1.99588989 C12.3833226,1.99588989 12.7329136,2.21500967 12.9,2.56 L15.44,7.68 L21.14,8.51 C21.5175771,8.56366684 21.8317559,8.82742187 21.95,9.19 C22.0581156,9.54391377 21.9619438,9.92860108 21.7,10.19 L17.58,14.19 L18.58,19.82 C18.652893,20.2027971 18.4967826,20.5930731 18.18,20.82 C17.9989179,20.9468967 17.7808835,21.010197 17.56,21 L17.56,21 Z"
        }
      />
    </SvgIcon>
  );
}

// Using for Alert
export function InfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d={
          "M12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 C17.5228475,22 22,17.5228475 22,12 C22,9.3478351 20.9464316,6.80429597 19.0710678,4.92893219 C17.195704,3.0535684 14.6521649,2 12,2 Z M13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,11 C11,10.4477153 11.4477153,10 12,10 C12.5522847,10 13,10.4477153 13,11 L13,16 Z M12,9 C11.4477153,9 11,8.55228475 11,8 C11,7.44771525 11.4477153,7 12,7 C12.5522847,7 13,7.44771525 13,8 C13,8.55228475 12.5522847,9 12,9 Z"
        }
      />
    </SvgIcon>
  );
}

export function WarningIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d={
          "M22.56,16.3 L14.89,3.58 C14.2597186,2.59400001 13.1702353,1.99737652 12,1.99737652 C10.8297647,1.99737652 9.74028139,2.59400001 9.11,3.58 L1.44,16.3 C0.888546003,17.2192471 0.869485343,18.3628867 1.39,19.3 C1.99197363,20.3551378 3.11522982,21.0046397 4.33,21 L19.67,21 C20.8765042,21.0128744 21.9978314,20.3797441 22.61,19.34 C23.146086,18.3926382 23.1269508,17.2292197 22.56,16.3 L22.56,16.3 Z M12,17 C11.4477153,17 11,16.5522847 11,16 C11,15.4477153 11.4477153,15 12,15 C12.5522847,15 13,15.4477153 13,16 C13,16.5522847 12.5522847,17 12,17 Z M13,13 C13,13.5522847 12.5522847,14 12,14 C11.4477153,14 11,13.5522847 11,13 L11,9 C11,8.44771525 11.4477153,8 12,8 C12.5522847,8 13,8.44771525 13,9 L13,13 Z"
        }
      />
    </SvgIcon>
  );
}

export function SuccessIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d={
          "M12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 C17.5228475,22 22,17.5228475 22,12 C22,9.3478351 20.9464316,6.80429597 19.0710678,4.92893219 C17.195704,3.0535684 14.6521649,2 12,2 Z M16.3,9.61 L11.73,15.61 C11.5412074,15.855247 11.2494966,15.9992561 10.94,16.0000145 C10.6322197,16.001658 10.3408221,15.861492 10.15,15.62 L7.71,12.51 C7.49028166,12.2277602 7.43782669,11.8497415 7.57239438,11.5183399 C7.70696206,11.1869384 8.00810836,10.9525017 8.36239438,10.9033399 C8.7166804,10.8541782 9.07028166,10.9977602 9.29,11.28 L10.92,13.36 L14.7,8.36 C14.917932,8.07418751 15.2717886,7.92635122 15.6282755,7.97217964 C15.9847624,8.01800806 16.2897207,8.25053875 16.4282755,8.58217966 C16.5668304,8.91382056 16.517932,9.29418753 16.3,9.58 L16.3,9.61 Z"
        }
      />
    </SvgIcon>
  );
}

export function ErrorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d={
          "M12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 C17.5228475,22 22,17.5228475 22,12 C22,9.3478351 20.9464316,6.80429597 19.0710678,4.92893219 C17.195704,3.0535684 14.6521649,2 12,2 Z M12,17 C11.4477153,17 11,16.5522847 11,16 C11,15.4477153 11.4477153,15 12,15 C12.5522847,15 13,15.4477153 13,16 C13,16.5522847 12.5522847,17 12,17 Z M13,13 C13,13.5522847 12.5522847,14 12,14 C11.4477153,14 11,13.5522847 11,13 L11,8 C11,7.44771525 11.4477153,7 12,7 C12.5522847,7 13,7.44771525 13,8 L13,13 Z"
        }
      />
    </SvgIcon>
  );
}

// Using for Checkbox
export function CheckboxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d={
          "M17.9 2.318A5 5 0 0 1 22.895 7.1l.005.217v10a5 5 0 0 1-4.783 4.995l-.217.005h-10a5 5 0 0 1-4.995-4.783l-.005-.217v-10a5 5 0 0 1 4.783-4.996l.217-.004h10Zm-.5 1.5h-9a4 4 0 0 0-4 4v9a4 4 0 0 0 4 4h9a4 4 0 0 0 4-4v-9a4 4 0 0 0-4-4Z"
        }
      />
    </SvgIcon>
  );
}

export function CheckboxCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d={
          "M17 2a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5Zm-1.625 7.255-4.13 4.13-1.75-1.75a.881.881 0 0 0-1.24 0c-.34.34-.34.89 0 1.24l2.38 2.37c.17.17.39.25.61.25.23 0 .45-.08.62-.25l4.75-4.75c.34-.34.34-.89 0-1.24a.881.881 0 0 0-1.24 0Z"
        }
      />
    </SvgIcon>
  );
}

export function CheckboxIndeterminateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d={
          "M17,2 C19.7614,2 22,4.23858 22,7 L22,7 L22,17 C22,19.7614 19.7614,22 17,22 L17,22 L7,22 C4.23858,22 2,19.7614 2,17 L2,17 L2,7 C2,4.23858 4.23858,2 7,2 L7,2 Z M15,11 L9,11 C8.44772,11 8,11.4477 8,12 C8,12.5523 8.44772,13 9,13 L15,13 C15.5523,13 16,12.5523 16,12 C16,11.4477 15.5523,11 15,11 Z"
        }
      />
    </SvgIcon>
  );
}

// Using for Radio Button
export function RadioIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d={"M12 2A10 10 0 1 1 2 12C2 6.477 6.477 2 12 2Zm0 1.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Z"} />
    </SvgIcon>
  );
}

export function RadioCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d={
          "M12 2A10 10 0 1 1 2 12C2 6.477 6.477 2 12 2Zm0 1.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17ZM12 7a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z"
        }
      />
    </SvgIcon>
  );
}

// Using for Select Input
export function InputSelectIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{
        right: 12,
        fontSize: 16,
        position: "absolute",
        pointerEvents: "none"
      }}
    >
      <path
        d={
          "M12,16 C11.7663478,16.0004565 11.5399121,15.9190812 11.36,15.77 L5.36,10.77 C4.93474074,10.4165378 4.87653776,9.78525926 5.23,9.36 C5.58346224,8.93474074 6.21474074,8.87653776 6.64,9.23 L12,13.71 L17.36,9.39 C17.5665934,9.2222295 17.8315409,9.14373108 18.0961825,9.17188444 C18.3608241,9.2000378 18.6033268,9.33252029 18.77,9.54 C18.9551341,9.74785947 19.0452548,10.0234772 19.0186853,10.3005589 C18.9921158,10.5776405 18.8512608,10.8311099 18.63,11 L12.63,15.83 C12.444916,15.955516 12.2231011,16.0153708 12,16 Z"
        }
      />
    </SvgIcon>
  );
}

//  Using for TreeView
export function TreeViewCollapseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d={
          "M18,3 C19.6568542,3 21,4.34314575 21,6 L21,6 L21,18 C21,19.6568542 19.6568542,21 18,21 L18,21 L6,21 C4.34314575,21 3,19.6568542 3,18 L3,18 L3,6 C3,4.34314575 4.34314575,3 6,3 L6,3 Z M18,5 L6,5 C5.44771525,5 5,5.44771525 5,6 L5,6 L5,18 C5,18.5522847 5.44771525,19 6,19 L6,19 L18,19 C18.5522847,19 19,18.5522847 19,18 L19,18 L19,6 C19,5.44771525 18.5522847,5 18,5 L18,5 Z M12,8 C12.5522847,8 13,8.44771525 13,9 L13,9 L13,11 L15,11 C15.5522847,11 16,11.4477153 16,12 C16,12.5522847 15.5522847,13 15,13 L15,13 L13,13 L13,15 C13,15.5522847 12.5522847,16 12,16 C11.4477153,16 11,15.5522847 11,15 L11,15 L11,13 L9,13 C8.44771525,13 8,12.5522847 8,12 C8,11.4477153 8.44771525,11 9,11 L9,11 L11,11 L11,9 C11,8.44771525 11.4477153,8 12,8 Z"
        }
      />
    </SvgIcon>
  );
}

export function TreeViewExpandIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d={
          "M18,3 C19.6568542,3 21,4.34314575 21,6 L21,6 L21,18 C21,19.6568542 19.6568542,21 18,21 L18,21 L6,21 C4.34314575,21 3,19.6568542 3,18 L3,18 L3,6 C3,4.34314575 4.34314575,3 6,3 L6,3 Z M18,5 L6,5 C5.44771525,5 5,5.44771525 5,6 L5,6 L5,18 C5,18.5522847 5.44771525,19 6,19 L6,19 L18,19 C18.5522847,19 19,18.5522847 19,18 L19,18 L19,6 C19,5.44771525 18.5522847,5 18,5 L18,5 Z M15,11 C15.5522847,11 16,11.4477153 16,12 C16,12.5522847 15.5522847,13 15,13 L15,13 L9,13 C8.44771525,13 8,12.5522847 8,12 C8,11.4477153 8.44771525,11 9,11 L9,11 Z"
        }
      />
    </SvgIcon>
  );
}

export function TreeViewEndIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d={
          "M18,3 C19.6568542,3 21,4.34314575 21,6 L21,6 L21,18 C21,19.6568542 19.6568542,21 18,21 L18,21 L6,21 C4.34314575,21 3,19.6568542 3,18 L3,18 L3,6 C3,4.34314575 4.34314575,3 6,3 L6,3 Z M18,5 L6,5 C5.44771525,5 5,5.44771525 5,6 L5,6 L5,18 C5,18.5522847 5.44771525,19 6,19 L6,19 L18,19 C18.5522847,19 19,18.5522847 19,18 L19,18 L19,6 C19,5.44771525 18.5522847,5 18,5 L18,5 Z M14,8.99420168 C14.2666375,8.99420168 14.5222334,9.10068735 14.71,9.29 C14.8993127,9.4777666 15.0057983,9.73336246 15.0057983,10 C15.0057983,10.2666375 14.8993127,10.5222334 14.71,10.71 L14.71,10.71 L13.41,12 L14.71,13.29 C14.8993127,13.4777666 15.0057983,13.7333625 15.0057983,14 C15.0057983,14.2666375 14.8993127,14.5222334 14.71,14.71 C14.5222334,14.8993127 14.2666375,15.0057983 14,15.0057983 C13.7333625,15.0057983 13.4777666,14.8993127 13.29,14.71 L13.29,14.71 L12,13.41 L10.71,14.71 C10.5222334,14.8993127 10.2666375,15.0057983 10,15.0057983 C9.73336246,15.0057983 9.4777666,14.8993127 9.29,14.71 C9.10068735,14.5222334 8.99420168,14.2666375 8.99420168,14 C8.99420168,13.7333625 9.10068735,13.4777666 9.29,13.29 L9.29,13.29 L10.59,12 L9.29,10.71 C8.89787783,10.3178778 8.89787783,9.68212217 9.29,9.29 C9.68212217,8.89787783 10.3178778,8.89787783 10.71,9.29 L10.71,9.29 L12,10.59 L13.29,9.29 C13.4777666,9.10068735 13.7333625,8.99420168 14,8.99420168 Z"
        }
      />
    </SvgIcon>
  );
}

export function BankingIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g opacity="0.32">
        <path
          d="M12 10.75C16.7038 10.75 19.4773 10.6481 20.868 10.5745C21.5117 10.5404 22 10.0077 22 9.36312V8.19432C22 7.74462 21.8551 7.30342 21.5366 6.98592C19.6683 5.12352 15.3747 2.72326 12.465 2.08812C12.1576 2.021 11.8424 2.021 11.535 2.08812C8.62535 2.72326 4.33171 5.12352 2.4634 6.98592C2.1449 7.30342 2 7.74462 2 8.19432V9.36312C2 10.0077 2.48829 10.5404 3.13195 10.5745C4.52271 10.6481 7.29615 10.75 12 10.75Z"
          fill="currentColor"/>
        <path
          d="M2.1787 18.7287C2.30863 18.3515 2.68627 18.1799 3.0846 18.1573C4.11911 18.0986 6.6517 18 12 18C17.3483 18 19.8809 18.0986 20.9154 18.1573C21.3137 18.1799 21.6914 18.3515 21.8213 18.7287C21.9176 19.0082 22 19.4175 22 20C22 20.5825 21.9176 20.9919 21.8213 21.2714C21.6914 21.6486 21.3137 21.8201 20.9154 21.8427C19.8809 21.9015 17.3483 22 12 22C6.6517 22 4.11911 21.9015 3.0846 21.8427C2.68627 21.8201 2.30863 21.6486 2.1787 21.2714C2.08243 20.9919 2 20.5825 2 20C2 19.4175 2.08243 19.0082 2.1787 18.7287Z"
          fill="currentColor"/>
      </g>
      <path
        d="M12 9C13.1046 9 14 8.10457 14 7C14 5.89543 13.1046 5 12 5C10.8954 5 10 5.89543 10 7C10 8.10457 10.8954 9 12 9Z"
        fill="currentColor"/>
      <path
        d="M13.9467 10.7438C13.3382 10.7478 12.6899 10.75 12 10.75C11.3101 10.75 10.6618 10.7478 10.0533 10.7438C10.0277 11.3951 10 12.5511 10 14.5C10 16.2195 10.0216 17.3217 10.0442 18.0047C10.65 18.0017 11.3009 18 12 18C12.6991 18 13.35 18.0017 13.9558 18.0047C13.9784 17.3217 14 16.2195 14 14.5C14 12.5511 13.9723 11.3951 13.9467 10.7438Z"
        fill="currentColor"/>
      <path
        d="M16.0542 10.7212C16.0284 11.3682 16 12.5284 16 14.5C16 16.2343 16.022 17.3407 16.0448 18.0223C17.8468 18.0443 19.1022 18.0792 19.9521 18.1123C19.976 17.4396 20 16.3128 20 14.5C20 12.4185 19.9684 11.2414 19.9415 10.6177C19.0121 10.6562 17.7369 10.6956 16.0542 10.7212Z"
        fill="currentColor"/>
      <path
        d="M4.05845 10.6177C4.98788 10.6562 6.26314 10.6956 7.94583 10.7212C7.97163 11.3682 8 12.5284 8 14.5C8 16.2343 7.97805 17.3407 7.9552 18.0223C6.15324 18.0443 4.89779 18.0792 4.04791 18.1123C4.024 17.4396 4 16.3128 4 14.5C4 12.4185 4.03165 11.2414 4.05845 10.6177Z"
        fill="currentColor"/>
    </SvgIcon>
  );
}

export function BookingIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path opacity="0.32"
        d="M16 5.58989C15.4034 5.56036 14.7474 5.5367 14.027 5.52107C13.4011 5.5075 12.7265 5.5 12 5.5C11.2735 5.5 10.5989 5.5075 9.97305 5.52107C9.25258 5.5367 8.59657 5.56036 8 5.58989V21.91C7.011 21.8611 6.1853 21.7961 5.5 21.7248V5.77514C4.81793 5.84603 4.27492 5.92304 3.84836 5.99635C2.96568 6.1481 2.35356 6.82915 2.25458 7.7193C2.1303 8.837 2 10.7291 2 13.75C2 16.7709 2.1303 18.663 2.25458 19.7807C2.35356 20.6709 2.96567 21.3519 3.84836 21.5037C5.1866 21.7337 7.6709 22 12 22C16.3291 22 18.8134 21.7337 20.1516 21.5037C21.0343 21.3519 21.6465 20.6709 21.7454 19.7807C21.8697 18.663 22 16.7709 22 13.75C22 10.7291 21.8697 8.837 21.7454 7.7193C21.6465 6.82915 21.0343 6.1481 20.1516 5.99635C19.7251 5.92304 19.1821 5.84603 18.5 5.77514V21.7248C17.8147 21.7961 16.989 21.8611 16 21.91V5.58989Z"
        fill="currentColor"/>
      <path
        d="M10.7917 2C9.68059 2 8.70279 2.73333 8.39169 3.8L7.8677 5.59658C8.49922 5.56393 9.19892 5.53786 9.97305 5.52107L10.3117 4.36C10.3739 4.14667 10.5695 4 10.7917 4H13.2083C13.4306 4 13.6261 4.14667 13.6883 4.36L14.027 5.52107C14.8011 5.53786 15.5008 5.56393 16.1324 5.59659L15.6083 3.8C15.2972 2.73333 14.3195 2 13.2083 2H10.7917Z"
        fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd"
        d="M18.5 21.7248C17.8147 21.7961 16.989 21.8611 16 21.91V5.58985C16.989 5.6388 17.8147 5.70385 18.5 5.7751V21.7248ZM8 5.58985V21.91C7.011 21.8611 6.1853 21.7961 5.5 21.7248V5.7751C6.1853 5.70385 7.011 5.6388 8 5.58985Z"
        fill="currentColor"/>
    </SvgIcon>
  );
}

export function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path opacity="0.32"
        d="M2.28099 19.6575C2.36966 20.5161 2.93261 21.1957 3.77688 21.3755C5.1095 21.6592 7.6216 22 12 22C16.3784 22 18.8905 21.6592 20.2232 21.3755C21.0674 21.1957 21.6303 20.5161 21.719 19.6575C21.8505 18.3844 22 16.0469 22 12C22 7.95305 21.8505 5.6156 21.719 4.34251C21.6303 3.48389 21.0674 2.80424 20.2231 2.62451C18.8905 2.34081 16.3784 2 12 2C7.6216 2 5.1095 2.34081 3.77688 2.62451C2.93261 2.80424 2.36966 3.48389 2.28099 4.34251C2.14952 5.6156 2 7.95305 2 12C2 16.0469 2.14952 18.3844 2.28099 19.6575Z"
        fill="currentColor"/>
      <path
        d="M13.9382 13.8559C15.263 13.1583 16.1663 11.7679 16.1663 10.1666C16.1663 7.8655 14.3008 6 11.9996 6C9.69841 6 7.83291 7.8655 7.83291 10.1666C7.83291 11.768 8.73626 13.1584 10.0612 13.856C8.28691 14.532 6.93216 16.1092 6.51251 18.0529C6.45446 18.3219 6.60246 18.5981 6.87341 18.6471C7.84581 18.8231 9.45616 19 12.0006 19C14.545 19 16.1554 18.8231 17.1278 18.6471C17.3977 18.5983 17.5454 18.3231 17.4876 18.0551C17.0685 16.1103 15.7133 14.5321 13.9382 13.8559Z"
        fill="currentColor"/>
    </SvgIcon>
  );
}

export function WhatsAppIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <g clipPath="url(#clip0_4899_1144)">
        <path
          d="M1.00074 9.87992C1.00028 11.5603 1.43935 13.2011 2.27423 14.6473L0.920898 19.5885L5.97764 18.2626C7.37627 19.024 8.94333 19.423 10.5358 19.4231H10.54C15.7969 19.4231 20.0762 15.1453 20.0785 9.88744C20.0795 7.33961 19.0882 4.9438 17.2871 3.14132C15.4863 1.33899 13.0914 0.345889 10.5396 0.344727C5.28198 0.344727 1.00299 4.62225 1.00082 9.87992"
          fill="url(#paint0_linear_4899_1144)"/>
        <path
          d="M0.660094 9.87721C0.659552 11.6181 1.11436 13.3175 1.97901 14.8155L0.577148 19.9339L5.81521 18.5605C7.25847 19.3474 8.88343 19.7622 10.5369 19.7629H10.5412C15.9868 19.7629 20.4198 15.3312 20.4221 9.88512C20.423 7.24574 19.3961 4.7638 17.5306 2.89674C15.665 1.02992 13.1844 0.00108527 10.5412 0C5.09467 0 0.662265 4.43101 0.660094 9.87721ZM3.77955 14.5575L3.58397 14.2471C2.7618 12.9398 2.32785 11.4291 2.32847 9.87783C2.33017 5.35109 6.0142 1.66822 10.5443 1.66822C12.7381 1.66915 14.7998 2.52434 16.3505 4.07597C17.9011 5.62775 18.7544 7.69054 18.7538 9.8845C18.7518 14.4112 15.0677 18.0946 10.5412 18.0946H10.5379C9.06405 18.0938 7.61854 17.698 6.35792 16.95L6.05792 16.7721L2.94955 17.5871L3.77955 14.5575Z"
          fill="url(#paint1_linear_4899_1144)"/>
        <path
          d="M8.07167 5.74776C7.88671 5.33668 7.69206 5.32838 7.51617 5.32117C7.37214 5.31497 7.20748 5.31544 7.04299 5.31544C6.87834 5.31544 6.61082 5.37738 6.38469 5.62427C6.15834 5.87141 5.52051 6.46862 5.52051 7.68327C5.52051 8.898 6.40524 10.0719 6.52857 10.2368C6.65206 10.4013 8.23655 12.9737 10.746 13.9633C12.8316 14.7857 13.256 14.6222 13.7086 14.5809C14.1614 14.5399 15.1694 13.9839 15.3751 13.4074C15.5809 12.8309 15.5809 12.3368 15.5192 12.2336C15.4575 12.1307 15.2928 12.0689 15.0459 11.9455C14.799 11.822 13.5852 11.2247 13.3589 11.1423C13.1325 11.06 12.9679 11.0189 12.8033 11.2661C12.6386 11.513 12.1659 12.0689 12.0217 12.2336C11.8778 12.3986 11.7337 12.4192 11.4869 12.2957C11.2398 12.1718 10.4447 11.9114 9.50136 11.0704C8.76741 10.416 8.2719 9.60784 8.12787 9.36063C7.98384 9.11381 8.11245 8.98001 8.23624 8.85699C8.34717 8.74637 8.48322 8.56869 8.60679 8.42459C8.72989 8.2804 8.77097 8.17753 8.8533 8.01288C8.9357 7.84807 8.89446 7.70389 8.83283 7.5804C8.77097 7.45691 8.29121 6.2359 8.07167 5.74776Z"
          fill="white"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_4899_1144" x1="958.801" y1="1924.72" x2="958.801" y2="0.344727"
            gradientUnits="userSpaceOnUse">
          <stop stopColor="#1FAF38"/>
          <stop offset="1" stopColor="#60D669"/>
        </linearGradient>
        <linearGradient id="paint1_linear_4899_1144" x1="992.825" y1="1993.39" x2="992.825" y2="0"
            gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9F9F9"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <clipPath id="clip0_4899_1144">
          <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
